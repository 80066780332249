import Router from 'next/router';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import dataLayer from '@/lib/dataLayer';
import isBlackouted from '@/utils/isBlackouted';
import userInteractionEvent from '@/utils/userInteractionEvent';
import useSearchQuery from '@/hooks/useSearchQuery';
import useDestination from '@/hooks/useDestination';
import { useDestination as v2UseDestination } from '@/v2/contexts/destination';
import useFlyingFrom from '@/libs/v2/hooks/useFlyingFrom';
import { ORIGINS } from '@/constants';
import useScrollIntoView from '@/hooks/useScrollIntoView';

import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';
import {
  handleOccupantsDataLayerEvent,
  handleDateDataLayerEvent,
  handleFlyingFromDatalayerEvent,
  handleTravellingToDatalayerEvent,
} from '@/v2/utils/dataLayer/searchEvents/changeHandlers';
import { searchSubmitEvent } from '@/v2/utils/dataLayer/searchEvents/events';

export const NOT_AVAILABLE_ROUTE_ERROR = 'NOT_AVAILABLE_ROUTE_ERROR';
export const EMPTY_FIELD_ERROR = 'EMPTY_FIELD_ERROR';

const isAvailableRoute = ({ origin, destination }) => {
  if (!origin || !destination) {
    return true;
  }

  return destination.searchConfig.originCodes.includes(origin.code);
};

const isAvailableOccupants = ({ occupants }) => {
  if (!occupants) {
    return false;
  }

  return occupants.adults > 0;
};

const validate = (data) => {
  const isFieldEmpty = (key) => !data[key];
  const isRouteAvailable = isAvailableRoute(data);
  const isOccupantsAvailable = isAvailableOccupants(data);

  return {
    origin: isFieldEmpty('origin') ? EMPTY_FIELD_ERROR : '',
    destination: isFieldEmpty('destination') ? EMPTY_FIELD_ERROR : '',
    departureDate: isFieldEmpty('departureDate') ? EMPTY_FIELD_ERROR : '',
    returnDate: isFieldEmpty('returnDate') ? EMPTY_FIELD_ERROR : '',
    occupants: !isOccupantsAvailable ? EMPTY_FIELD_ERROR : '',
    route: !isRouteAvailable ? NOT_AVAILABLE_ROUTE_ERROR : '',
  };
};

const initialState = {
  data: {
    occupants: { adults: 2, children: 0, infants: 0 },
  },
  dirty: false,
};

const initialiser = (initialState) => initialState;

const reducer = (state, action) => {
  const data = {
    ...state.data,
    ...action.value,
  };

  switch (action.type) {
    case 'CHANGE_ROUTE': {
      const { origin, destination, departureDate, returnDate, occupants } =
        data;

      const blackoutDates =
        destination && origin && destination.blackoutDates[data.origin.code];

      return {
        ...state,
        data: {
          departureDate,
          returnDate,
          occupants,
          origin: action.value.origin || origin,
          // Reset the destination every time the flying from changes on the Search page or Home page
          destination:
            action.value.origin && !action.value.isDestinationPage
              ? null
              : destination,
          ...(blackoutDates &&
            isBlackouted(blackoutDates, { departureDate, returnDate }) && {
              departureDate: undefined,
              returnDate: undefined,
            }),
        },
      };
    }

    case 'CHANGE_DATE_RANGE': {
      const { departureDate, returnDate } = data;

      return {
        ...state,
        data: {
          ...state.data,
          departureDate,
          returnDate,
        },
      };
    }

    case 'CHANGE_TRAVELLERS': {
      return {
        ...state,
        data: {
          ...state.data,
          occupants: action.value,
        },
      };
    }

    case 'FORM_TOUCHED': {
      return {
        ...state,
        dirty: true,
      };
    }

    case 'INITIALISE': {
      return initialiser(action.value);
    }

    default:
      return state;
  }
};

const constructInitialStateFromSearchQuery = (
  searchQuery,
  destination,
  isDestinationPage,
) => ({
  data: {
    destination,
    origin:
      searchQuery?.originCode &&
      (destination?.searchConfig?.origins ?? []).find(
        (o) => o.code === searchQuery.originCode,
      ),
    departureDate: searchQuery.departureDate,
    returnDate: searchQuery.returnDate,
    occupants: {
      adults: searchQuery.adults ? searchQuery.adults : 2,
      children: searchQuery.children ? searchQuery.children : 0,
      infants: searchQuery.infants ? searchQuery.infants : 0,
    },
    isDestinationPage,
  },
  dirty: false,
});

const useSearchForm = (props = {}) => {
  const shouldUseNewEvents = useGA4Events();
  const { isHomeSearch = false, isSearchPage = false } = props;
  const { searchQuery, setSearchQuery, isReady } = useSearchQuery();
  const isDestinationPage = !isHomeSearch && !isSearchPage;

  const d = useDestination();
  const dV2 = v2UseDestination();

  const dest = d || dV2;

  const { setOriginCode } = useFlyingFrom(
    isHomeSearch ? ORIGINS.map((o) => o.code) : dest?.searchConfig.originCodes,
  );

  const scrollToTitle = useScrollIntoView('#destination-title', 0);

  /*
    The initialiser function is required because on first load, this hook can have an
    empty searchQuery, which means it will not initialise state properly.
  */
  const [state, dispatch] = useReducer(
    reducer,
    isHomeSearch
      ? initialState
      : constructInitialStateFromSearchQuery(
          searchQuery,
          dest,
          isDestinationPage,
        ),
    initialiser,
  );

  useEffect(() => {
    if (!isReady) return;

    // This is required because the Search Page's first load does not have searchQuery initialised,
    // which means that the useReducer will not update the state correctly on first load.
    if (!isHomeSearch) {
      const newState = constructInitialStateFromSearchQuery(
        searchQuery,
        dest,
        isDestinationPage,
      );

      dispatch({
        type: 'INITIALISE',
        value: newState,
      });
    } else {
      if (searchQuery?.originCode) {
        const origin = ORIGINS.find((o) => o.code === searchQuery?.originCode);
        changeRoute({ origin });
      }
    }
  }, [isReady, isHomeSearch, isDestinationPage, dest, searchQuery?.originCode]);

  const changeRoute = useCallback(
    (value) => {
      // We don't want the URL to change (and have the page update with new search results on the search page)
      if (!isSearchPage) {
        setOriginCode(value?.origin?.code);
      }

      // GA4 event handlers
      if (shouldUseNewEvents) {
        if (!!value?.origin?.code) {
          handleFlyingFromDatalayerEvent(
            state?.data?.origin?.code,
            value?.origin?.code,
          );
        } else {
          handleTravellingToDatalayerEvent(
            state?.data?.destination?.code || state?.data?.destination?.title,
            value?.destination?.code || value?.destination?.title,
          );
        }
      }

      return dispatch({
        type: 'CHANGE_ROUTE',
        value: { ...value, isDestinationPage },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isDestinationPage,
      isSearchPage,
      setOriginCode,
      shouldUseNewEvents,
      state?.data?.destination?.title,
      state?.data?.origin?.name,
    ],
  );

  const changeDateRange = useCallback(
    (value) => {
      //GA4 event handler
      if (shouldUseNewEvents) {
        handleDateDataLayerEvent(state.data, value);
      }

      dispatch({
        type: 'CHANGE_DATE_RANGE',
        value,
      });
    },
    [shouldUseNewEvents, state.data],
  );

  const changeTravellers = useCallback(
    (value) => {
      //GA4 event handler
      if (shouldUseNewEvents) {
        handleOccupantsDataLayerEvent(state?.data?.occupants, value);
      }
      return dispatch({
        type: 'CHANGE_TRAVELLERS',
        value,
      });
    },
    [shouldUseNewEvents, state?.data?.occupants],
  );

  const error = useMemo(() => validate(state.data), [state]);

  const submit = () => {
    dispatch({
      type: 'FORM_TOUCHED',
    });

    const { origin, destination, occupants, departureDate, returnDate } =
      state.data;

    if (!origin || !destination) {
      return;
    }

    if (shouldUseNewEvents) {
      searchSubmitEvent();
    }

    // If user is on the homepage or updating the destination on a search page,
    // re-route them
    if (isHomeSearch || dest?.name !== destination?.name) {
      dataLayer.push(
        userInteractionEvent(
          'Supported Search',
          `${origin.name} - ${destination.title}`,
          'Home Page Search',
        ),
      );

      Router.push({
        pathname: `/search/${destination.name}`,
        query: {
          originCode: origin.code,
          departureDate,
          returnDate,
          ...occupants,
        },
      }).then(() => {
        if (isSearchPage) {
          scrollToTitle({ behavior: 'smooth' });
        } else {
          window.scroll(0, 0);
        }
      });
    } else {
      setSearchQuery(
        {
          originCode: origin.code,
          destinationCode: destination.searchConfig.destinationCode,
          departureDate,
          returnDate,
          ...occupants,
        },
        true,
      ).then(() => {
        if (isSearchPage) {
          scrollToTitle({ behavior: 'smooth' });
        }
      });
    }
  };

  const { data } = state;

  return {
    data,
    error,
    changeRoute,
    changeDateRange,
    changeTravellers,
    submit,
    searchQuery,
  };
};

export default useSearchForm;
